<template>
  <div class="inner-header d-flex justify-content-between align-items-center">
    <div class="brand-logo">
      <a>
        <img />
      </a>
    </div>
    <div class="d-flex justify-content-between align-items-center mr-3" v-if="this.$store.state.isAuthenticated">
      <div class="dropdown header-profile dropdown-menu-right">
        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img src="~@/assets/images/user-dropdown.png" alt="" />
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style="min-width: 3rem;">
          <ul class="mb-0 p-0">
            <li class="align-items-center d-flex logout cursor-pointer" @click="logout()">
              <img src="~@/assets/images/left-arrow-grey.png" alt="" />
              <span class="logout cursor-pointer">Logout</span>
              <div class="loading_block_outer" v-if="loading">
                <Loading3 msg="Logging out" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "headerComponent",
  emits: ["clicked-rm-details"],
  components: {},
  data() {
    return {};
  },
  async mounted() { },
  methods: {
    logout() {
      this.$store.commit("setToken", "");
      this.$store.commit("loginUser", false);
      this.$store.commit("setUserId", "");
      sessionStorage.clear();
      window.location.reload();
    }
  },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';

.inner-header {
  position: absolute;
  padding: 14px 47px;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 72px;
  /* UI Properties */
  background: #008449 0% 0% no-repeat;
  box-shadow: 0px 20px 20px #00000014;
  opacity: 1;

  .dashbrd {

    background: #E5F0FF;
    width: 125px;
    height: 35px;
    outline: none;
    border: 0;
    border-radius: 50px;
    font-size: 14px;
    line-height: 14px;
    padding: 10px;
    margin-right: 10px;
    color: #008449;
    position: relative;

    &:hover {
      .tooltip-label {
        opacity: 1;
        top: 100%;
      }
    }

    img {
      display: inline-block;
      width: 16px;
    }

    span {
      vertical-align: middle;
    }
  }

  .logout {
    cursor: pointer;

    &:hover {
      color: #fa9d17;
    }
  }

  ul {
    li {
      padding-right: 20px;
      margin-bottom: 5px;

      img {
        margin-right: 5px;
      }

      span {
        right: 0;
      }
    }
  }
}

.header-profile {
  button {
    background: #000000;
    padding: 4px 9px;
    border-radius: 5px;
    color: #FFFFFF;
    border: 1px solid #E5F0FF;
    outline: none;
    width: 60px;
    border-radius: 50px;

    img {
      width: 18px;
    }
  }

  .dropdown-menu {
    padding: 10px;
    font-size: 14px;
    transform: none !important;
    left: auto !important;
    right: 0;
    top: 40px !important;
  }
}

.brand-logo {
  a {
    background-image: url("https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/kvb+logo.jpg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    width: 170px;
    height: 50px;

    img {
      object-fit: cover;
      object-position: center;
    }
  }
}
</style>
<!-- @import "~@/assets/scss/components/SavingsProduct/investReturn.scss"; -->