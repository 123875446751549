import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import backendApi from "./apis/axios-backend";
import { getCookie, setCookie } from "./utils/common";
import "bootstrap";
import store from "./store";

var app = createApp(App);
app.use(store).use(router).mixin({
  methods: {
    backendApi,
    getCookie,
    setCookie,
  },
});

app.mount("#app");
