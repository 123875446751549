import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
export default createStore({
  state: {
    isAuthenticated: false,
    token: "",
    userId: "",
  },
  mutations: {
    loginUser(state,isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    setToken(state, token) {
      state.token = token;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
  },
  actions: {
    loginUser({ commit }) {
      commit("loginUser");
    },
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  getters: {
    isAuthenticatedUser(state) {
      return state.isAuthenticated;
    },
    getToken(state) {
      return state.token;
    },
  },
  modules: {},
});
