<template>
    <div>
        <login />
    </div>
</template>

<script>
import login from "@/components/loginPage.vue";

export default {
  name: 'LoginPage',
  components: {
     login
  }
}
</script>

<style scoped lang="scss">

</style>