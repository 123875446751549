export const getRequestData = (api, data) => {

  return {
    body: data,
    url: apis[api]["url"],
    baseURL: apis[api]["baseURL"] == undefined ? process.env.VUE_APP_URL : process.env.VUE_APP_URL_RMD,
    source: apis[api]["baseURL"] == undefined ? "DEFAULT" : apis[api]["baseURL"],
  };
};

const apis = {
  //get uploded files
  userLogin:{
    url: "/v2/lifesave/login",
    baseURL: "RMD"
  },
  userLoginOverride:{
    url: "/v2/lifesave/login?override=true",
    baseURL: "RMD"
  },
  redirectUrl:{
    url: "/v2/redirection",
    baseURL: "RMD"
  },
  getCookieToken: {
    url: "/v2/token",
    baseURL: "RMD",
  }
 
};

