<template>
  <div class="landing-page-container">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <h1 class="covered">{{ msg }}</h1>
          <h3>Compare, Buy & Save on all your Insurance needs</h3>
          <div class="products">
            <div class="row">
              <div v-for="(product, index) in products" :key="index" class="col-6 col-md-4 col-lg-3 column"
                @click="redirectFunction(product.lob)">
                <span>
                  <div class="productbox">
                    <img :src="product.image" />
                  </div>
                  <span class="productname">{{ product.name }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "SelectorPage",
  data() {
    return {
      transactionId: "",
      redirecturl: "",
      username: "",
      password: "",
      secretKey: "",
      msg: "DISCOVER THE WORLD OF INSURANCE",
      products: [
        {
          name: "Life Term as RM",
          image: require("@/assets/term.png"),
          lobName: "LIFETERM",
          roleId: "",
          lob: 'term',
          link: "https://kvb1sbuat.term.kvbank.in/"
        },
        {
          name: "Health as RM",
          image: require("@/assets/health.png"),
          lobName: "HEALTH",
          roleId: "",
          lob: 'health',
          link: "https://kvb1sbuat.health.kvbank.in/",
        },
        {
          name: "Investment as RM",
          image: require("@/assets/savings.png"),
          lobName: "LIFESAVE",
          roleId: "",
          lob: 'lifesave',
          link: "https://kvb1sbuat.savings.kvbank.in/"
        },
      ]
    };
  },
  props: {
  },
  methods: {
    async redirectFunction(lob) {
      try {
        // const token = this.$store.state.token;
        // this.decoded = jwt_decode(token);
        // console.log("token", this.decoded)
        // this.current_user = this.decoded;
        // let transId = this.current_user.PAYLOAD.transactionId;
        const body = {
          "email": "HMDAVE306@REDIFFMAIL.COM",
          "roleId": "2",
          "userId": this.$store.state.userId,
          "lob": lob,
          "override": true
        }
        const token = this.$store.state.token;
        const headers = {
          "secretKey": "1SB",
          "Authorization": `Bearer ${token}`,
        }
        const redirectResponse = await this.backendApi('redirectUrl', body, headers);
        const url = redirectResponse.data.data.url;

        window.open(url, "_self")
      }
      catch (err) {
        console.log('token is null: ', err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

.footer {
  margin-top: 100px;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: block;
  margin: 0 10px;
}

a {
  color: #42b983;
  text-decoration: none;
  width: 208px;
  height: 82px;
  cursor: pointer;
}

.btn {
  margin-top: 3%;
  background-color: #008cba;
  /* Blue */
  border: 2px solid #008cba;
  border-radius: 4px;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: flex;
  font-size: 16px;
}

.landing-page-container {
  min-height: calc(100vh - 72px);
  padding: 30px 0;
  margin: 0;
  background-color: #f1f5ff;
  background: #f1f5ff url(~@/assets/images/happy-family.png) no-repeat;
  background-size: 50%;
  background-position: right top;

  h1 {
    font-weight: 700;
    color: #008449;
  }

  h3 {
    margin-top: 20px;
    font-size: 16px;
    color: #858585;
  }
}

.discount-badge {
  color: #fff;
  font-size: 16px;
  position: absolute;
  top: 100px;
  left: -60px;
  font-family: "Public Sans", sans-serif;
}

.discount-badge div.discount-badge-inner {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  text-transform: uppercase;
  text-align: center;
  transform: translateY(-50%);
}

.discount-badge-amount {
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans", sans-serif;
  margin-top: 5px;
}

.discount-badge-amount strong {
  font-size: 40px;
  margin: 0 4px 0 0;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
}

.discount-text-inner span {
  font-size: 17px;
  display: block;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}

.discount-badge div.discount-badge-inner b:before {
  content: "";
  position: absolute;
  height: 1px;
  width: 11px;
  background: #fff;
  right: 35px;
  top: 8px;
}

div.discount-badge-inner b:before {
  right: auto;
  left: 35px;
}

.discount-badge div.discount-badge-inner b:before,
.discount-badge div b:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 11px;
  background: #fff;
  right: 35px;
  top: 8px;
}

.discount-badge div i {
  font-size: 14px;
  font-style: normal;
  display: block;
  margin: 13px 0 8px 0;
  line-height: 15px;
  font-family: Open Sans, sans-serif, Myriad Pro;
}

.discount-badge div b {
  font-size: 12px;
  position: relative;
  display: block;
  font-weight: bolder;
}

.discount-text-inner span.off-text {
  font-size: 12px;
}

.position-relative {
  position: relative;
}

.clear {
  height: 100px;
  width: 30%;
}

.products {
  text-align: center;
  margin-top: 15px;

  .column {
    padding: 15px;
  }
}

.covered {
  text-transform: uppercase;
}

.productbox {
  background-color: #008449;
  /* margin-left: 10%; */
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px auto;
  padding: 20px;
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    width: 105px;
    height: 105px;
    border-radius: 50%;
    border: 1px solid #009900;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    transition: 0.3s all;
  }

  &:hover {
    img {
      transform: scale(1.15);
    }
  }
}

.productname {
  /* text-align: center; */
  align-items: center;
  width: 200px;
  font-size: 16px;
  font-weight: 500;
  color: #6e6e6e !important;
  text-decoration: none !important;
}

.disabled {
  background: transparent linear-gradient(270deg, grey 0%, grey 100%) 0% 0% no-repeat padding-box;
}
</style>
