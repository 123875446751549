export function setCookie(name, value, duration = 15, unit = "minutes") {
  let expires = "";

  if (duration) {
    const date = new Date();
    if (unit === "days") {
      date.setTime(date.getTime() + duration * 24 * 60 * 60 * 1000);
    } else if (unit === "minutes") {
      date.setTime(date.getTime() + duration * 60 * 1000);
    }
    expires = `; expires=${date.toUTCString()}`;
  }

  const secureFlag = window.location.protocol === "https:" ? "; Secure" : "";
  const domain = "; domain=.kvbank.in";

  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value || ""
  )}${expires}; path=/; SameSite=Strict${secureFlag}${domain}`;
}

export function getCookie(name) {
  const nameEQ = `${encodeURIComponent(name)}=`;
  const cookiesArray = document.cookie.split(";");

  for (let i = 0; i < cookiesArray.length; i++) {
    let cookie = cookiesArray[i].trim();
    if (cookie.startsWith(nameEQ)) {
      return decodeURIComponent(cookie.substring(nameEQ.length));
    }
  }

  return null;
}

export function deleteCookie(name) {
  const secureFlag = window.location.protocol === "https:" ? "; Secure" : "";
  const domain = "; domain=.kvbank.in";

  document.cookie = `${encodeURIComponent(
    name
  )}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=Strict${secureFlag}${domain}`;
}
