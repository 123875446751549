<template>
  <div>
    <HeaderComponent />
    <router-view />
  </div>
</template>

<script>
import HeaderComponent from './components/Header/headerComponent.vue';


export default {
  name: 'App',
  components: {
    HeaderComponent,
  }
}
</script>

<style>
html,
body {
  margin: 0px !important;
  padding: 0px !important;
  overflow-x: hidden;
}

#app {
  font-family: 'Open sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  padding-top: 72px;
}

img {
  max-width: 100%;
}

.headerArea {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 72px;
  background: #008449 0% 0% no-repeat;
  box-shadow: 0px 20px 20px #00000014;
  opacity: 1;
}


.logo {
  top: 17px;
  left: 31px;
  width: 150px;
  height: 37px;
  position: absolute;
  background: transparent url('https://insurance-manufacturer.s3.ap-south-1.amazonaws.com/logos/kvb+logo.jpg') 0% 0% no-repeat padding-box;
  background-size: 100% 100%;
  object-fit: scale-down;
  opacity: 1;
}
</style>
