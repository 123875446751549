<template>
  <section class="login_page d-flex align-items-center">
    <div class="container">
      <div>
        <div class="row align-items-center">
          <div class="col-12 col-md-6 d-none d-md-block text-center">
            <img src="~@/assets/images/homeBanner.jpg" alt="" class="img-login">
          </div>
          <div class="col-12 col-md-6 ml-auto col-right">
            <h2>Welcome</h2>
            <h5>Login into your account</h5>
            <div class="card border-0 shadow rounded-3">
              <div class="card-body p-4 p-sm-5">
                <form @submit.prevent="login()">
                  <div class="form-floating mb-3">
                    <label class="form-header" for="floatingInput">Username </label>
                    <input type="text" class="form-control" :class="errormsgUsername && 'error'" v-model="email"
                      id="floatingInput" placeholder="Please enter your Username here" autocomplete="one-time-code">
                    <label class="errormsg">{{ this.errormsgUsername }}</label>
                  </div>
                  <div class="form-floating mb-3">
                    <label class="form-header" for="floatingPassword">Password</label>
                    <input :type="show ? 'text' : 'password'" class="form-control" :class="errormsgPassword && 'error'"
                      v-model="password" autocomplete="one-time-code" id="floatingPassword" placeholder="Password">
                  </div>
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" @change="showPassword()" id="showPass">
                    <label class="custom-control-label form-header" for="showPass">Show Password</label>
                  </div>
                  <label class="errormsg">{{ this.errormsgPassword || this.sessionError }}</label>
                  <div class="d-grid">
                    <button class="btn btn-primary btn-login text-uppercase fw-bold" type="submit">Login</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "LoginPage",
  components: {},
  data() {
    return {
      email: "",
      password: "",
      show: false,
      errormsgUsername: "",
      errormsgPassword: "",
      sessionError: "",
      token: "",
      decoded: "",
      current_user: "",
      override: false,
    };
  },
  mounted() {
    const token = this.$store.state.token;
    if (!token) {
      this.$store.commit("setToken", "");
      this.$store.commit("loginUser", false);
      this.$store.commit("setUserId", "");
    }
  },
  methods: {
    gotoSelector() {
      this.$router.push("/selector-page");
    },
    showPassword() {
      this.show = !this.show;
    },
    async login() {
      if (!this.email || !this.password) {
        this.errormsgUsername = !this.email ? "Please enter a valid username " : "";
        this.errormsgPassword = !this.password ? "Please enter password" : "";
      } else {
        this.errormsgUsername = "";
        this.errormsgPassword = "";
        this.sessionError = "";
        let qData = {
          "distributorID": "kvb",
          "email": this.email,
          "password": this.password,
          "authUsing": "basicAuth",
          "insuranceCompanyCode": "ICICI",
          "UITrackingRefNo": "ed1bbbd2-d3fc-4df9-befe-952d46996958",
          "OTPTransactionId": "",
          "OTP": ""
        };

        var headers = {
          Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
          "Content-Type": "application/json",
        };
        const url = this.override ? 'userLoginOverride' : 'userLogin';
        try {
          const response = await this.backendApi(url, qData, headers);
          this.override = false;
          if (response == 'error') {
            this.apiError = true;
          } else if (response && response.data) {
            if (response.data.errors.length == 0) {
              this.token = response.data.data.token.access_token;
              this.$store.commit("setToken", this.token);
              this.$store.commit("setUserId", this.email);
              this.$store.commit("loginUser", true);
              this.gotoSelector();
            } else {
              if (response.data.errors[0].errorIdentifier === "MAXIMUM_SESSIONS_ERROR") {
                this.sessionError = "Maximum session limit reached. Click login button again to override.";
                this.override = true;
              } else {
                this.errormsgPassword = response.data.errors[0].errorDisplayMsg || response.data.errors[0].errorDisplayMessage;
              }
            }
          }
        } catch (error) {
          console.log('error msg block login', error);
        }
      }
    }
  },
};
</script>
<style scoped lang="scss">
@import "~@/assets/scss/components/loginpage.scss";
</style>
